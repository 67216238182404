
import React from 'react';
import '../css/firstBlock.scss';
import instImg from '../img/instImg.png';
import discordImg from '../img/discordImg.png';
import tgImg from '../img/tgImg.png';
import xImg from '../img/xImg.png';
import nft1 from '../img/nft-1.png';
import nft2 from '../img/nft-2.png';
import grandpa from '../img/grandpa.png';



class FirstBlock extends React.Component {
    render() {
        return (
            <div id="fBlock">
                <div className="left-fBlock">
                    <div className="social-table">
                        <img src={instImg} alt="" />
                        <img src={discordImg} alt="" />
                        <img src={tgImg} alt="" />
                        <img src={xImg} alt="" />
                    </div>
                    <div className="left-fBlock-text">
                        Join our friendly charitable team of Lucky and grandpa Stepan, and you can contribute to our common well-being!
                    </div>
                    <button className="purpButton">VIEW ON OPENSEA</button>
                    <div className="left-fBlock-nft">
                        <img src={nft1} alt="" />
                        <img src={nft2} alt="" />
                    </div>
                </div>
                <div className="right-fBlock">
                    <h1>CATMAN</h1>
                    <h2>NFT<br/>COLLECTION</h2>
                    <div className="right-fBlock-text">
                        Teach other people how to be kinder and take care of those who need help. He can share his stories and inspire others to do similar acts of kindness.

                    </div>

                </div>
                <div className="grandpa-fBlock">
                    <img src={grandpa} alt="" />
                </div>
            </div>

        )
    }
}


export default FirstBlock;
