
import React from 'react';
import '../css/catmanLine.scss';



class CatmanLine extends React.Component {
    render() {
        return (
            <div id="catmanLine">

                <div class="scrolling-text">
                    <p>CATMAN</p>
                    <p>CATMAN</p>
                    <p>CATMAN</p>
                    <p>CATMAN</p>
                    <p>CATMAN</p>
                    <p>CATMAN</p>
                    <p>CATMAN</p>
                    <p>CATMAN</p>
                    <p>CATMAN</p>
                    <p>CATMAN</p>
                    <p>CATMAN</p>
                    <p>CATMAN</p>
                    <p>CATMAN</p>
                    <p>CATMAN</p>
                    <p>CATMAN</p>
                </div>

                <div class="scrolling-text">
                    <p>CATMAN</p>
                    <p>CATMAN</p>
                    <p>CATMAN</p>
                    <p>CATMAN</p>
                    <p>CATMAN</p>
                    <p>CATMAN</p>
                    <p>CATMAN</p>
                    <p>CATMAN</p>
                    <p>CATMAN</p>
                    <p>CATMAN</p>
                    <p>CATMAN</p>
                    <p>CATMAN</p>
                    <p>CATMAN</p>
                    <p>CATMAN</p>
                    <p>CATMAN</p>
                </div>
            </div>
        )
    }
}


export default CatmanLine;
