
import React from 'react';
import '../css/foBlock.scss';
import nft1 from '../img/nft-1.png';
import nft2 from '../img/nft-2.png';
import nft3 from '../img/nft-3.png';
import team from '../img/team.png';





class FoBlock extends React.Component {
    render() {
        return (
            <div id="foBlock">
                <div className="foBlock-title">OUR<br />
                    COLLECTION</div>
                <div className="slider">
                    <div className="nft"><img src={nft1} alt="" /><div className="price">0.44 ETH</div></div>
                    <div className="nft"><img src={nft2} alt="" /><div className="price">0.44 ETH</div></div>
                    <div className="nft"><img src={nft3} alt="" /><div className="price">0.44 ETH</div></div>
                    <div className="nft-text"><h2>LUCKY</h2><p>Join our friendly charitable team of Lucky and grandpa Stepan, and you can contribute to our common well-being!</p><button className="purpButton">READ STORY</button></div>
                </div>
                <div className="team-title">THIS IS<span> CATMAN </span>TEAM</div>
                <div className="team">
                    
                    <div className="teamate">
                        <img src={team} alt="" />
                        <div className="team-name">Olexandr Seba</div>
                        <div className="team-rank">CEO</div>
                    </div>
                    <div className="teamate">
                        <img src={team} alt="" />
                        <div className="team-name">Olexandr Seba</div>
                        <div className="team-rank">CEO</div>
                    </div>
                    <div className="teamate">
                        <img src={team} alt="" />
                        <div className="team-name">Olexandr Seba</div>
                        <div className="team-rank">CEO</div>
                    </div>
                    <div className="teamate">
                        <img src={team} alt="" />
                        <div className="team-name">Olexandr Seba</div>
                        <div className="team-rank">CEO</div>
                    </div>

                </div>

            </div>
        )
    }
}


export default FoBlock;
