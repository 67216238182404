
import React from 'react';
import Logo from '../img/logo.png';
import '../css/footer.scss';



class Footer extends React.Component {
    render() {
        return (
            <div id="footer">
                <div className="leftMenu">
                    <a href="#">Home</a>
                    <a href="#">About us</a>
                    <a href="#">NFT Catalog</a>
                </div>
                <div className="logo"><img src={Logo} alt="" /></div>

                <div className="rightMenu">

                    <a href="#">News</a>
                    <a href="#">Blog</a>

                    <button className="wallet">Connect Wallet</button>
                </div>
            </div>
        )
    }
}


export default Footer;
