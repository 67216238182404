
import React from 'react';
import did from '../img/did.png';
import '../css/triBlock.scss';

import twit from '../img/twit.png';
import x from '../img/x.png';
import tg from '../img/tg.png';
import disc from '../img/disc.png';



class TriBlock extends React.Component {
    render() {
        return (
            <div id="triBlock">
                <div className="triBlock-left">
                    <h1>ABOUT OF NFT</h1>
                    <p>Grandpa Stepan was an ordinary pensioner who spent most of his time in his small house on the outskirts of the city. One day, while driving through the rainy streets, he saw an abandoned little kitten, which was hungry and freezing. Grandpa Stepan could not pass by and decided to take the "find" to his home.<br/><br/>

The kitten was very grateful, and grandfather Stepan quickly fell in love with his new friend. He decided to name him Lucky and began courting him. Grandfather Stepan fed the kitten, played with it and took care of its health.<br/><br/>

Over time, grandpa Stepan noticed that Lucky loves people very much, always behaves playfully, cheerfully and childishly cordially. This inspired grandpa Stepan to start doing good deeds in his city. So, he began to spend more time on the streets, helping passers-by, the elderly and those who needed help.</p>
                <div className="social-buttons">
                    <img src={twit} alt="" /><img src={x} alt="" /><img src={tg} alt="" /><img src={disc} alt="" />
                </div>
                <div className="triBlock-left-ico"></div>
                </div>
                <div className="triBlock-right">
                    <img src={did} alt="" />
                    <div className="did-shadow"></div>
                </div>

            </div>
        )
    }
}


export default TriBlock;
