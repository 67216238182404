
import React from 'react';
import cat from '../img/block2.png';
import '../css/secondBlock.scss';



class SecondBlock extends React.Component {
    render() {
        return (
            <div id="secondBlock">
                <button className='purpButton'>READ STORY</button>
                <h1>GRANDA<br />
                    STEPAN AND LUCKY</h1>
                <div className="secondblock-flex">
                    <div className="second-left">We are a charitable company that was founded in 2014 by a young team with the help of investors to support people in  need, orphans, sick children, homeless animals, shelters, nursing homes, victims of the war in Ukraine, the Armed Forces of Ukraine and all those who need help, understanding and reliable  shoulder provided in time.
                        Our company has got a clear mission - to feed, provide medicines and other necessary means to everyone who needs it, anywhere in the world!
                        For this purpose we have organized enough staff of specialists, which consists of volunteer IT-specialists, cooks of different nationalities.
                        And all these people are united for one and the same  thing - to help and to win, as well as to overcome  difficult times, crisis, famine, consequences of cataclysms, cruelty to animals, social inequality and of course  to help millions of people to find basic things for life:  home, food, clothes, medical care, social and psychological protection.
                    </div>
                    <div className="second-right"><img src={cat} alt="" /></div>
                </div>

            </div>
        )
    }
}


export default SecondBlock;
