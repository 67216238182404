
import React from 'react';

import Header from './Header';
import Footer from './footer';
import FirstBlock from './firstBlock';
import CatmanLine from './catmanLine';
import SecondBlock from './secondBlock';
import TriBlock from './triBlock';
import FoBlock from './foBlock';

class App extends React.Component {
    render() {
        return (
            <>
                <Header />
                <FirstBlock />
                <CatmanLine />
                <SecondBlock />
                <CatmanLine />
                <TriBlock />
                <CatmanLine />
                <FoBlock />
                <Footer />
            </>
        )
    }
}


export default App;
